@import 'src/scss/base';

.root {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
}

.logo {
  width: 100%;
  padding: 0 10px 20px;
}

.menu {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
  font-weight: $font-weight-medium;
}

.menuItem {
  position: relative;
}

.menuItem a {
  display: block;
  padding: 10px 44px;

  path {
    stroke: $c-medium-blue;
  }

  &:hover {
    background: $c-primary-blue;
    border-radius: 6px;

    path {
      stroke: $c-white;
    }
  }
}

.menuItem svg {
  position: absolute;
  top: 11px;
  left: 11px;
}
