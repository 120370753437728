@import "src/scss/base";

$gutters: 10px;

.root {
  width: 100%;
}

.breadcrumbs {
  width: 100%;
  margin-bottom: 10px;
}

.formContent .fields {
  display: none;
}

.isOpen .fields {
  display: grid;
}

.headingLarge {
  font-size: 24px;
  font-weight: $font-weight-bold;
}

.fields {
  display: none;
  width: calc(100% + #{$gutters});
  margin: 1em -#{$gutters} 0 0;
  font-size: 14px;
  line-height: 1.5;
}

.inputWrapper label {
  color: $c-grey-700;
  display: block;
  padding-bottom: 4px;
  font-weight: $font-weight-medium;
}

.inputWrapper > div {
  width: 100%;
}

.inputWrapper {
  position: relative;
}

.inputWrapper input,
.inputWrapper select {
  width: 100%;
  padding: 9px 13px;
  border: 1px solid $c-input-border;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  font-size: inherit;
  line-height: inherit;
}

.inputWrapper input[type="email"] {
  padding-right: 35px;
}

div.inputIcon {
  width: auto;
  position: absolute;
  bottom: 13px;
  right: 10px;
}

.actions {
  display: flex;
}
