@import "src/scss/base";

.root {
  display: flex;
  width: 100%;
  height: 100%;
}

.left {
  min-height: 100vh;
  background: $c-dark-blue;
  color: $c-white;
}

.innerLeft {
  top: 0;
  left: 0;
  height: 100%;
  padding: 20px 8px;
  min-width: 12rem;
}

.right {
  position: relative;
  width: 100%;
  background: $c-grey-xlt;
}

.innerRight {
  width: 100%;
  min-height: 100vh;
  padding: 0 32px;
}
