@import "src/scss/base";

.documentLink {
  h3 {
    &:after {
      display: block;
      content: "";
      border-bottom: solid 2px #3792e7;
      transform: scaleX(0);
      transform-origin: 0% 50%;
      transition: all 250ms ease-in-out;
    }
  }

  &:hover {
    h3 {
      color: #3792e7;
      &:after {
        transform: scaleX(1);
      }
    }
  }
}

.documentImportant {
  color: #b91c1c;

  h3 {
    &:after {
      border-bottom: solid 2px #b91c1c;
    }
  }

  &:hover {
    h3 {
      color: #b91c1c;
    }
  }
}
