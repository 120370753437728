@import 'src/scss/base';

.root {
  width: 100%;
  padding: 0 16px;
  background: $c-white;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.contentWrapper {
  display: flex;
  padding: 42px 0 51px;
}

.content {
  display: block;
}

.image {
  margin-right: 20px;
}

.linkWrapper {
  display: inline-block;
  padding-bottom: 16px;
}

.link {
  display: flex;
  align-items: center;
  padding: 12px 18px;
  color: $c-primary-blue;
}

.link svg {
  margin-right: 10px;
}
