@import "src/scss/base";

.root {
  width: 100%;
}

.breadcrumbs {
  width: 100%;
  margin-bottom: 10px;
}

.contentWrapper {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 41px;
}

.callToActions {
  display: flex;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background: $c-grey-200;
  }

  @media (min-width: 1024px) {
    &:before {
      content: "";
      position: absolute;
      left: 50%;
      top: 0;
      width: 1px;
      height: 100%;
      background: $c-grey-200;
    }
  }
}

.cta {
  flex-basis: 100%;
  margin-left: 32px;
}

@media (min-width: 769px) {
  .cta {
    flex-basis: 50%;
  }
}
