.tableWrapper {
  padding-top: 30px;
}

.tableWrapper table {
  table-layout: fixed;
  width: 100%;
}

.tableWrapper tr {
  vertical-align: top;
}

.tableWrapper th:nth-child(1) {
  width: 210px;
}

.tableWrapper th:nth-child(2),
.tableWrapper th:nth-child(4) {
  width: 150px;
}

.tableWrapper th:nth-child(3) {
  width: 100px;
}

.tableWrapper th:nth-child(5) {
  width: 210px;
}

.tableWrapper th:nth-child(6) {
  width: 90px;
}

.tableWrapper th:nth-child(7) {
  width: 135px;
}

.tableWrapper tr td:nth-child(3) {
  float: none;
}

.tableWrapper input {
  max-width: 100%;
}

.tableWrapper td div {
  display: inline-block;
  word-break: break-word;
  white-space: pre-line;
  overflow-wrap: break-word;
  -ms-word-break: break-word;
  word-break: break-word;
}

.tableWrapper td:nth-child(7) div {
  display: block;
}

.tableWrapper tr td:nth-child(1) > div {
  display: inline-block;
  max-width: 50%;
  margin-right: 10px;
}
