@import "src/scss/base";

.root {
  display: inline-flex;
  align-items: center;
  padding: 9px 17px;
  border-radius: 6px;
  background-color: none;
  font-size: 14px;
  outline: none;
  cursor: pointer;
}

.root:disabled,
.root[disabled] {
  background-color: $c-grey-lt;
  color: $c-grey-dk;
}

.fullWidth {
  display: block;
  width: 100%;
}

.primary {
  background: $c-primary-blue;
  color: $c-white;
  transition: all 0.2s ease-in-out;

  &:hover {
    background: $c-medium-blue;
    transform: scale(1.03);
  }
}

.secondary {
  background: $c-white;
  border: 1px solid $c-input-border;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
}

.icon {
  display: inline-block;
  padding-right: 5px;
}
