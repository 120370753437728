@import 'src/scss/base';

.root {
  position: relative;
  display: inline-block;
  width: 346px;
  margin-top: 21px;
  margin-right: 0.5em;
  color: $c-grey-md;
}

.root input {
  padding-right: 30px;
}

.searchIcon {
  position: absolute;
  right: 8px;
  top: 8px;
}
