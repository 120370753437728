@import "src/scss/base";

.root {
  width: 100%;
}

.breadcrumbs {
  width: 100%;
  margin-bottom: 10px;
}

.contentWrapper {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 41px;
}

.callToActions {
  display: flex;
  margin-top: 32px;
  position: relative;

  &:before {
    content: "";
    position: absolute;
    top: calc(50%);
    height: 1px;
    width: 100%;
    background: $c-grey-200;
  }

  @media (min-width: 1024px) {
    &:before {
      left: calc(50%);
      top: 0;
      width: 1px;
      height: 100%;
      background: $c-grey-200;
    }
  }
}

.inputWrapper {
  width: 100%;
}

.profileWrapper {
  display: flex;
  background: $c-white;
  margin-top: 44px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.formContent {
  display: flex;
  background: $c-white;
  margin-top: 44px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
  position: relative;
  overflow: hidden;
}

.fields {
  width: 100%;
}

.fieldRow {
  width: 100%;
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
}

.rblHorizontal label {
  margin-right: 0.67em;
  padding: 0.5em 0;
}

.rblHorizontal input[type="radio"] {
  margin-right: 0.33em;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 24px 0;
}
