@import "src/scss/base";

.root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 16px;
  background: $c-white;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.organizationSelect {
  min-width: 250px;
  display: flex;
  align-items: center;
}

.organizationSelect select {
  padding-right: initial;
  padding-left: 2.5rem;
  background-position: left 0.5rem center;
  box-shadow: none;
  border: none;
}

.organizationSelect select:focus {
  outline: none;
  box-shadow: none;
}
