@import "src/scss/base";

.root {
  width: 100%;
}

.heading {
  font-size: 30px;
  font-weight: $font-weight-black;
  padding: 24px 0 12px;
}

.inputWrapper {
  position: relative;
  width: 100%;
  font-size: 14px;
}

.inputWrapper label {
  color: $c-grey-700;
  display: block;
  padding-bottom: 4px;
  font-weight: $font-weight-medium;
}

.inputWrapper input {
  width: 100%;
  padding: 9px 13px;
  border: 1px solid $c-input-border;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);

  &:disabled {
    background: $c-input-border;
  }
}

.actions {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
}

.checkboxWrapper {
  cursor: pointer;
}

.checkbox {
  margin-right: 5px;
}

.forgotPasswordLink {
  color: $c-primary-blue;
}

.recoverInfo {
  width: 100%;
}

.recoverHeading {
  padding-bottom: 4px;
  font-size: 18px;
  font-weight: $font-weight-medium;
}

.recoverText {
  font-size: 14px;
  color: $c-grey-500;
  margin-bottom: 24px;
}

.recoverOptions {
  margin-top: 24px;
  margin-bottom: 24px;
}
