// Colors variables
// Color variables should start with 'c-'
$c-black: #111827;
$c-white: #ffffff;
$c-primary-blue: #3792e7;
$c-dark-blue: #002a57;
$c-medium-blue: #3e80c8;
$c-light-blue: #cfe5fc;
$c-grey-dk: #6a6a6a;
$c-grey-md: #9ca3af;
$c-grey-lt: #e0e0e0;
$c-grey-xlt: #f8f8f8;
$c-grey-200: #e5e7eb;
$c-grey-300: #d1d5db;
$c-grey-500: #6b7280;
$c-grey-700: #374151;
$c-green-lt: #ecfdf5;
$c-green-dk: #065f46;
$c-red-700: #b91c1c;
$c-input-border: #d1d5db;

// Font weights
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;
$font-weight-black: 800;
$font-weight-xblack: 900;

// Widths and Layout
$l-gutter-xs: 8px;
$l-gutter-sm: ($l-gutter-xs * 2);
$l-gutter-md: ($l-gutter-sm * 2);
$l-gutter-lg: ($l-gutter-sm * 3);
$l-gutter-xl: ($l-gutter-sm * 4);

// Breakpoint variables
// Used in bp mixin in _mixins.scss
$breakpoints: (
  sm: 480px,
  md: 768px,
  lg: 960px,
);

// z-index map
// Used in z() function used sitewide.
// Remove the examples below when you are ready to layer your own.
$z-layers: (
  layer-2: (
    2,
  ),
  default: (
    1,
  ),
  below: (
    -1,
  ),
);

// Transitions
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$ease-1: 300ms $easeOutCubic;
$t-all: 0.5s all;
$t-background: 0.5s background linear;
$t-transform: 0.5s transform $easeOutCubic;
