@import 'src/scss/base';

.root {
  position: relative;
  display: inline-block;
}

.encDatePicker {
  width: 8.33rem;
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.calendarIcon {
  position: absolute;
  right: 8px;
  top: 11px;
  color: $c-grey-md;
}
