@import "src/scss/base";

.option {
  transition: all 150ms ease-in-out;
  &:hover {
    color: white;
    background-color: #3792e7;
    cursor: pointer;

    .check {
      color: white;
    }
  }
  .check {
    color: #3792e7;
  }
}

.open {
  display: block;
}

.selected {
  min-width: 10rem;
  transition: all 0.2s ease-in-out;
  padding: 9px 13px;
  &:hover {
    cursor: pointer;
  }
}
.closed {
  display: none;
}
