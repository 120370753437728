@import "src/scss/base";

.root {
  position: relative;
  width: 100%;
  padding: 16px 48px;
  margin-bottom: 24px;
  border-radius: 6px;
  font-size: 14px;
  font-weight: $font-weight-medium;
}

.success {
  background: $c-green-lt;
  color: $c-green-dk;
  border: 1px solid $c-green-dk;
}

.error {
  background: $c-white;
  color: $c-red-700;
  border: 1px solid $c-red-700;
}

.icon {
  position: absolute;
  width: 20px;
  height: 20px;
  left: 18px;
  top: 18px;
}
