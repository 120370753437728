@import "src/scss/base";

.root {
  width: 100%;
  min-height: 100vh;
  font-size: 14px;
}

.contentWrapper {
  width: 100%;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 41px;
}

.headingLarge {
  font-size: 24px;
  font-weight: $font-weight-bold;
}

.headerColumn {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 41px;
}

.ctaLink {
  margin-left: auto;
}

.headingSmall {
  padding-bottom: 4px;
  font-size: 18px;
  font-weight: $font-weight-medium;
}

.paraLight {
  color: $c-grey-500;
}

.invite {
  margin-left: auto;
}

.tableWrapper {
  margin-top: 12px;
}

.tableWrapper tr {
  cursor: pointer;
}

.tableWrapper th:nth-child(2) {
  width: 150px;
}

.tableWrapper th:nth-child(3) {
  width: 50px;
}

.tableWrapper tr td:nth-child(3) {
  float: right;
}

.noResults {
  padding: 20px 0;
  font-size: 18px;
}
