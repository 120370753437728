@import "src/scss/base";

.root {
  position: absolute;
  bottom: -1.2rem;
  left: 0;
  color: $c-red-700;
  font-size: 11px;
  font-weight: 600;
}
