@import "src/scss/base";

.root {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  transition: all 0.25s linear;
  cursor: default;

  &.isOpen {
    visibility: visible;
    opacity: 1;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: rgba($c-black, 0.4);
}

.contentWrapper {
  position: absolute;
  right: -100%;
  top: 0;
  height: 100%;
  width: 80%;
  z-index: 2;
  background: $c-white;
  padding: 20px;
  box-shadow: -2px 5px 12px rgba($c-black, 0.1);
  transition: right 0.5s linear;
  overflow-x: hidden;
  overflow-y: scroll;
}

.isOpen .contentWrapper {
  right: 0;
}

.headingWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid $c-grey-200;
}

.heading {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 10px;
}

.closeButton {
  margin-left: auto;
  padding: 9px 17px;
  background: $c-white;
  border: 1px solid $c-grey-300;
  border-radius: 6px;
  box-shadow: 0px 1px 2px rgba($c-black, 0.05);
}

.isSecondary .overlay {
  background: none;
}

.isSecondary .heading {
  cursor: pointer;
}

.isSecondary .contentWrapper {
  right: -100%;
}

.isSecondary.isOpen .contentWrapper {
  right: 0;
}
