.root {
  display: flex;
  width: 100%;
  height: calc(100vh - 88px);
}

.left {
  width: 100%;
  max-width: 576px;
  padding: 48px 96px;
}

.right {
  position: relative;
  width: 100%;
}

.rightInner {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.coverAsset {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
