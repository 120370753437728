$color-avaliable: #3792e7;
$shadow-avaliable: 0 6px 9px 0 #3792e74a;
$shadow: 0 6px 9px 0 #00000021;

.root {
  display: block;
}

.wrapper {
  width: 100%;
}

.calendar {
  width: 100%;
  box-shadow: $shadow;

  [class$="--next"] {
    border-left-color: $color-avaliable;
  }
  [class$="--previous"] {
    border-right-color: $color-avaliable;
  }

  [class$="__triangle"] {
    left: 0 !important;
    right: 0px !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}

.day {
  transition: all 0.2s ease-in-out;
  border-radius: 10rem;

  &:hover {
    background-color: $color-avaliable;
    font-weight: bold;
    border-radius: 10rem;
    color: white;
    box-shadow: $shadow-avaliable;
  }

  &:focus {
    outline: none;
  }
  &[class$="--today"] {
    color: $color-avaliable;
    background-color: transparent;
  }

  &[class*="--today"]:hover {
    box-shadow: none !important;
  }

  &[class*="--selected"] {
    background-color: $color-avaliable;
    color: white;
    font-weight: bold;
  }

  &[class*="--disabled"]:hover {
    box-shadow: none !important;
    background-color: transparent !important;
    cursor: default !important;
    color: #ccc;
    font-weight: normal;
  }
}
