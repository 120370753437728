@import "src/scss/base";

.root {
  width: 100%;
}

.formContent {
  display: flex;
  position: relative;
  background: $c-white;
  margin-top: 44px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
}

.fields,
.description {
  width: 100%;
  padding-right: 1rem;
}

.description {
  max-width: 314px;
}

.createLink {
  display: inline-block;
  align-items: center;
  padding: 12px 18px;
  color: $c-primary-blue;
}

.actions {
  display: flex;
  justify-content: flex-end;
  padding: 24px 0;
}

.actions button {
  margin-left: 12px;
}
