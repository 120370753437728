@import "src/scss/base";

.root {
  display: flex;
  margin-bottom: 32px;
  width: 100%;
}

.tile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  background: $c-white;

  &:before {
    content: "";
    position: absolute;
    top: -0.75rem;
    width: 100%;
    height: 1px;
    background: $c-grey-200;
  }

  @media (min-width: 1024px) {
    &:before {
      content: "";
      position: absolute;
      left: -0.75rem;
      top: 0;
      width: 1px;
      height: 100%;
      background: $c-grey-200;
    }
  }

  &:first-child::before {
    display: none;
  }
}
