@import 'src/scss/base';

.root {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
}

.left {
  width: auto;
}

.right {
  margin-left: auto;
}
