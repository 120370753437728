.icon {
  position: absolute;
  top: 15px;
  right: 10px;
  height: 1rem;
}

.icon svg {
  width: 10px;
}

.responsive {
  td {
    text-overflow: ellipsis !important;
    white-space: normal !important;
    max-width: 100% !important;
  }
}
